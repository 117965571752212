





























































































































































































































































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import RadioDialog from '@/components/common/RadioDialog/index.vue'
import pagination from '@/components/common/pagination/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import { exportStatistic } from '@/utils/export'
import pageTitle from '@/components/common/pageTitle.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import PassFailed from '../PassFailed/index.vue'
import AccessImage from '@/components/dashboard/AccessImage/index.vue'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { areaPath } from '@/utils/getAreaPath'
import _ from 'lodash'
import { variables } from '@/libs/theme'
import { tableScrollTop } from '@/utils/dom'

@Component({
  name: 'detailPage',
  components: {
    FeatureImg,
    pagination,
    CopyText,
    pageTitle,
    RadioDialog,
    InputSearch,
    PassFailed,
    AccessImage,
    SecurityText
  }
})
export default class DetialPage extends Vue {
  @Prop({ type: String, default: 'base' }) moduleName: 'base' | 'europa'
  @Prop({ default: false }) showIdentityAddress: boolean

  @Ref('paginationNode') readonly paginationNode: pagination
  @Ref('exportDialog') readonly exportDialog: RadioDialog
  @Ref('searchNode') readonly searchNode: InputSearch
  @Ref('failedNode') readonly failedNode: PassFailed

  get apiModule() {
    return this.$api[this.moduleName]
  }

  get user() {
    return this.$store.state.user || {}
  }
  get currentAreaId() {
    return this.$store.state.areaId
  }

  get typeOptions() {
    return [
      { value: 0, label: '人脸识别' },
      { value: 1, label: '身份证' },
      { value: 2, label: '刷卡' },
      { value: 3, label: '二维码' },
      { value: 7, label: '其他' }
    ]
  }

  private shadow9Color = variables.shadow9Color
  private query = {
    limit: 20,
    offset: 0
  }
  private showFlag = false
  private tableHeight: any = null
  private sels: any[] = []
  private passList: any = []
  private loading = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private passFilterVal: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')],
    searchType: '0'
  }
  private searchPar: any = null
  private areaIds: any = null
  private isShowPassFailed = false
  private personImg: any[] = [
    {
      name: '注册图片',
      url: null,
      EqualWH: true,
      BigPicture: false
    },
    {
      name: '通行图片',
      url: null,
      EqualWH: false,
      Typesetting: 'vertical',
      BigPicture: true
    }
  ]
  placeholder = '请输入姓名'
  selectList = [
    { label: '姓名', value: '0' },
    { label: 'ID', value: '1' },
    { label: '身份证号', value: '2' }
  ]
  showExtendInfo = false
  extendInfo = {
    info: '',
    comment: ''
  }
  restoring = false
  activeTag = 'pass'
  first = true
  tags: AnyObj[] = []

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  mounted() {
    this.getList(this.query.limit, 0)
  }
  deactivated() {
    this.showFlag = false
    this.showExtendInfo = false
  }

  isExtendInfo(val: AnyObj) {
    const isNilTem = _.isNil(val.temperature)
    const isNilComment = _.isNil(val.comment)
    return isNilTem && isNilComment
  }
  selectType(val: string) {
    switch (val) {
      case '0':
        this.placeholder = '请输入姓名'
        break
      case '1':
        this.placeholder = '请输入ID'
        break
      case '2':
        this.placeholder = '请输入身份证号'
    }
  }
  tabClick(tab: AnyObj) {
    if (tab.index === '0') {
      this.activeTag = 'pass'
    }
    if (tab.index === '1') {
      this.activeTag = 'failed'
      if (this.first) {
        this.isShowPassFailed = true
        this.$nextTick(() => {
          this.failedNode.init()
        })
      }
      this.first = false
    }
  }

  private showExportDialog() {
    this.exportDialog.show()
  }
  private async exportResult(exportAll = true) {
    let params = this.searchPar
    delete params.limit
    delete params.offset
    if (!exportAll) {
      // 导出选中的参数
      if (this.sels.length === 0) return
      params = { ids: this.sels.map(i => i.id), detail: true }
    }

    if (this.searchPar !== null) {
      const res = await this.apiModule.getPassagesExport(params)
      exportStatistic(res.data, 'csv', 'passages.csv')
    }
  }
  private async pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getList(this.query.limit, this.query.offset)
  }

  async getList(pageSize = 20, offset = 0) {
    this.loading = true
    try {
      const resp = await this.getPassList(pageSize, offset)
      if (resp.data && resp.data.data) {
        const { data, count } = resp.data
        this.passList = data.map((item: AnyObj) => {
          Object.assign(item, areaPath(item.areas))
          return item
        })
        this.paginationNode.init({ total: count ? count : 0 })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }

  private async getPassList(pageSize = 20, offset = 0) {
    const val = this.passFilterVal
    const [startTime, endTime] = val.dateRange
    const identity = val.identity

    const par: AnyObj = {
      startTime,
      endTime,
      limit: pageSize,
      offset: offset,
      areaIds: val.areaIds && val.areaIds.length > 0 ? val.areaIds : [this.root],
      identity,
      detail: true
    }
    if (val.passType || val.passType === 0) {
      Object.assign(par, { types: [val.passType] })
    }

    if (val.searchText && !val.searchText.match(/^[ ]*$/)) {
      switch (val.searchType) {
        case '0':
          par.name = val.searchText
          break
        case '1':
          par.personIds = [val.searchText]
          break
        case '2':
          par.identityNumber = val.searchText
      }
    }
    this.searchPar = par

    return this.apiModule.getPassagesSearch(par)
  }

  private handleFilter() {
    this.paginationNode.initPage({ current: 1 })
    this.getList(this.query.limit, 0)
  }

  private async seePassImg(obj: AnyObj) {
    if (obj.targetImageUrl || obj.sceneImageUrl) {
      this.showFlag = true
      this.personImg[0].url = obj.targetImageUrl
      this.personImg[1].url = obj.sceneImageUrl
    }
  }
  seeExtendInfo(obj: AnyObj) {
    const isNilTem = _.isNil(obj.temperature)
    const isNilComment = _.isNil(obj.comment)
    if (!isNilTem || !isNilComment) {
      this.showExtendInfo = true
      let text = ''
      if (!isNilTem) text = `体温${obj.temperature}℃`
      this.extendInfo = {
        info: text,
        comment: obj.comment
      }
    }
  }

  private selsChange(val: any) {
    this.sels = val
  }
}
