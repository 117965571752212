













import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
  name: 'pagination',
  components: {}
})
export default class Pagination extends Vue {
  @Prop({ default: 0 }) private size: number
  @Prop({ default: false }) private pageshow: boolean

  private pageSizes: number[] = [20, 50, 100]
  private pageSize = 1
  private total = 0
  private activeTag: string
  private preciseSize: number
  private indistinctSize: number

  pageNo = 0

  @Watch('pageshow')
  watchPageShow(val: boolean) {
    if (val == true) {
      this.pageNo = 0
    }
  }

  created() {
    this.pageSize = this.size
  }
  public initPage({ current, size }: { current: number; size?: number }) {
    this.pageNo = current
    if (size) this.pageSize = size
  }
  public init({ total }: { total: number }) {
    this.total = total
  }

  currentChange(val: number) {
    this.pageNo = val
    this.change()
  }

  sizeChange(val: number) {
    if (this.activeTag === 'precise') {
      this.preciseSize = val
    }

    if (this.activeTag === 'indistinct') {
      this.indistinctSize = val
    }

    this.pageSize = val
    this.pageNo = 1
    this.change()
  }

  change() {
    this.$emit('change', this.$data)
  }

  getPageInfo() {
    return this.$data
  }
}
