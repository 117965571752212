export function getPosition(el: HTMLElement) {
  return {
    top: el.offsetTop,
    left: el.offsetLeft
  }
}

export function removePX(el: string | undefined) {
  if (el) {
    return Number(el.replace('px', ''))
  }
  return 0
}

export function getHeight(el: HTMLElement | null) {
  if (!el) return 0
  const styles = el.style
  const height = el.offsetHeight
  const borderTopWidth = Number(styles.borderTopWidth)
  const borderBottomWidth = Number(styles.borderBottomWidth)
  const paddingTop = Number(styles.paddingTop)
  const paddingBottom = Number(styles.paddingBottom)
  return height - borderBottomWidth - borderTopWidth - paddingTop - paddingBottom
}

export function getWidth(el: HTMLElement | null) {
  if (!el) return 0
  const styles = el.style
  const width = el.offsetWidth
  const borderLeftWidth = Number(styles.borderLeftWidth)
  const borderRightWidth = Number(styles.borderRightWidth)
  const paddingLeft = Number(styles.paddingLeft)
  const paddingRight = Number(styles.paddingRight)
  return width - borderLeftWidth - borderRightWidth - paddingRight - paddingLeft
}

const cubic = (value: number) => Math.pow(value, 3)
const easeInOutCubic = (value: number) =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2

export function backTop(el: HTMLElement) {
  if (!el) return
  const beginTime = Date.now()
  const beginValue = el.scrollTop
  const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16))
  const frameFunc = () => {
    const progress = (Date.now() - beginTime) / 500
    if (progress < 1) {
      el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
      rAF(frameFunc)
    } else {
      el.scrollTop = 0
    }
  }
  rAF(frameFunc)
}

export function Deferred() {
  let _resolve, _reject
  const promise: AnyObj = new Promise((resolve, reject) => {
    _resolve = resolve
    _reject = reject
  })
  promise.resolve = _resolve
  promise.reject = _reject
  return promise
}

export function tableScrollTop() {
  const wrapper = document.getElementsByClassName('el-table__body-wrapper')[0]
  if (wrapper) wrapper.scrollTop = 0
}
