















































import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
@Component({
  name: 'index'
})
export default class InputSearch extends Vue {
  @Prop({ default: '' }) private placeholder: string
  @Prop({
    default: () => {
      return []
    }
  })
  private selectList: any
  private searchText = ''
  private focusSearch = false
  private seachSelect = ''
  private lightButton = false
  @Watch('searchText')
  watchSearchText() {
    this.$emit('input', this.searchText)
  }
  @Watch('seachSelect')
  watchSeachSelect(val: string) {
    this.$emit('select', val)
  }

  created() {
    this.init()
  }
  init() {
    const [option] = this.selectList

    if (option) {
      this.seachSelect = option.value
    }
  }
  private search() {
    this.$emit('change', this.searchText)
  }
  restore() {
    this.searchText = ''
    this.init()
  }
  private hoverHandel(val: string) {
    if (val === 'enter') {
      this.lightButton = true
    } else {
      this.lightButton = false
    }
  }
  private focusInput(val: string) {
    if (val === 'focus') {
      this.focusSearch = true
    } else {
      this.focusSearch = false
    }
  }
}
