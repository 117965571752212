


















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class FeatureImg extends Vue {
  @Prop({ required: true }) src: string
  @Prop({ required: false, default: false }) cover: boolean
  @Prop({ required: false, default: false }) noListen: boolean
  @Prop({ required: false, default: () => ({}) }) Position: AnyObj

  imgClass = 'el-icon-loading'
  observer = null

  @Watch('src')
  watchSrc() {
    this.imgClass = 'el-icon-loading'
    const imgWrap = this.$refs.imgWrap as HTMLElement
    imgWrap.style.display = 'none'
  }

  activated() {
    this.onImgLoad()
  }

  onImgLoad() {
    this.imgClass = ''
    const container = this.$el
    this.$nextTick()
    const imgWrap = this.$refs.imgWrap as HTMLElement
    if (!imgWrap) return
    const img = this.$refs.img as HTMLImageElement
    const containerRate = container.clientWidth / container.clientHeight
    let faceObj
    if (this.Position) {
      faceObj = this.Position
    }
    const naturalHeight = img ? img.naturalHeight : 1
    const naturalWidth = img ? img.naturalWidth : 1
    const hh = container.clientHeight / naturalHeight
    const ww = container.clientWidth / naturalWidth
    const imgRate = naturalWidth / naturalHeight
    if (containerRate > imgRate) {
      imgWrap.style.transform = `translate(-50%, -50%) scale(${this.cover ? ww : hh})`
      if (faceObj && this.cover) {
        const faceAreaH = (faceObj.Y + faceObj.Height) * ww // 图片方框底边高度
        const imgH = naturalHeight * ww // 显示的图片高度
        const hb = (container.clientHeight + imgH) / 2
        const ht = (imgH - container.clientHeight) / 2
        if (faceAreaH > hb - 2) {
          imgWrap.style.top = `calc(50% + ${
            (imgH - container.clientHeight) * 0.5 - (faceAreaH - container.clientHeight + 2)
          }px)`
          imgWrap.style.left = '50%'
        } else if (faceObj.Y * ww < ht) {
          imgWrap.style.top = `calc(50% + ${
            (imgH - container.clientHeight) * 0.5 - (faceObj.Y * ww - 1)
          }px)`
          imgWrap.style.left = '50%'
        }
      }
    } else {
      imgWrap.style.transform = `translate(-50%, -50%) scale(${this.cover ? hh : ww}) `
      if (faceObj && this.cover) {
        const faceAreaW = (faceObj.X + faceObj.Width) * hh
        const imgW = hh * naturalWidth
        const wr = (container.clientWidth + imgW) / 2
        const wl = (imgW - container.clientWidth) / 2
        if (faceAreaW > wr) {
          imgWrap.style.left = `calc(50% + ${
            (imgW - container.clientWidth) * 0.5 - (faceAreaW - container.clientWidth + 1)
          }px)`
          imgWrap.style.top = '50%'
        } else if (faceObj.X * hh < wl) {
          imgWrap.style.left = `calc(50% + ${
            (imgW - container.clientWidth) * 0.5 - (faceObj.X * hh - 1)
          }px)`
          imgWrap.style.top = '50%'
        }
      }
    }
    imgWrap.style.display = 'block'
    if (faceObj) {
      const area = this.$refs.faceArea as HTMLElement
      ;(area.style.width = faceObj.Width),
        (area.style.height = faceObj.Height),
        (area.style.top = faceObj.Y),
        (area.style.left = faceObj.X)
    }
  }

  onError() {
    this.imgClass = 'el-icon-error'
  }

  altClick(e: Event) {
    const { src } = e.target as HTMLImageElement
    const evObj = document.createEvent('MouseEvents')
    const nameStartIndex = src.lastIndexOf('/')
    const downloadName = src.slice(nameStartIndex + 1) + '.jpg'
    const $a = document.createElement('a')
    $a.setAttribute('href', src)
    $a.setAttribute('download', downloadName)
    evObj.initMouseEvent(
      'click',
      true,
      true,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      true,
      false,
      0,
      null
    )
    $a.dispatchEvent(evObj)
  }
}
