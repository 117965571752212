





















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({
  name: 'copyText'
})
export default class CopyText extends Vue {
  @Prop({}) private value: string
  @Prop({}) private allpersonalTitle: string
  @Prop({}) private type: string

  @Ref('copy') copy: HTMLDivElement

  private isShow = false
  private show = true
  private clickCopy() {
    const textarea = document.createElement('textarea')
    this.copy.appendChild(textarea)
    textarea.value = this.value
    textarea.select()

    document.execCommand('copy')

    this.$message({
      message: '复制成功！',
      type: 'success'
    })

    this.copy.removeChild(textarea)
  }
  get subStringVal() {
    let str = ''
    if (this.value) {
      str = this.value
    }
    let subStr = ''

    if (this.type && this.type === 'label') {
      subStr = str.length > 5 ? str.substring(0, 4) + '...' : str.length === 0 ? '-' : str
    } else if (this.type && this.type === 'tagCount' && str.length > 0) {
      subStr = '+' + (str.length - 1)
    } else if (this.type && this.type === 'photoDialog' && str.length > 0) {
      subStr = str + ' '
    } else if (this.type && this.type === 'address' && str.length > 0) {
      subStr = str.length > 30 ? str.substring(0, 30) + '...' : str.length === 0 ? '-' : str
    } else {
      subStr = str.length > 8 ? str.substring(0, 8) + '...' : str.length === 0 ? '-' : str
    }
    return subStr
  }
  get showValue() {
    let result = ''
    if (this.value) {
      if (Array.isArray(this.value) && this.value.length > 0) {
        this.value.forEach((element, index) => {
          if (index > 0) {
            result = result + element.name + ','
          }
        })
        result = result.substr(0, result.length - 1)
        return result
      } else {
        return String(this.value)
      }
    } else {
      return '-'
    }
  }
}
