









































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'radioDialog',
  components: {}
})
export default class RadioDialog extends Vue {
  @Prop({ default: '', required: true }) title: string
  @Prop() tooltip?: string
  @Prop({ default: [], required: true }) options: {
    name: string
    value: string | number | boolean
  }[]
  @Prop({ required: false }) defaultValue: string | number | boolean
  @Prop({ required: true }) onConfirm: (value: string | number | boolean) => Promise<void>

  private visible = false
  private loading = false
  private value: any = ''

  created() {
    this.initValue()
  }
  deactivated() {
    this.visible = false
  }
  initValue() {
    if (this.defaultValue) {
      this.value = this.defaultValue
    } else if (this.options.length > 0) {
      this.value = this.options[0].value
    }
  }

  show() {
    this.visible = true
  }

  hide() {
    this.loading = false
    this.visible = false
  }

  private submit() {
    this.loading = true

    this.onConfirm(this.value)
      .then(() => this.hide())
      .catch(() => this.hide())
  }
}
