
















































/**
 * 关于通行图片组件说明
 * @displayName AccessImage
 */
import { Vue, Component, Prop } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'

@Component({ components: { FeatureImg } })
export default class PersonVisit extends Vue {
  /**
   * 是否显示 Dialog
   * @values true、false
   */
  @Prop({ default: false }) showFlag: boolean
  /**
   * 展示多个通行图片的配置(url:通行图片的url，name:显示名称，EqualWH:图片宽高是否相等，Typesetting: horizontal宽高比为16：9、vertical宽高比为9：16（EqualWH为false时有效），BigPicture:是否支持查看大图)
   * @values [{url:'',EqualWH: true,BigPicture:false,name:''}]
   */
  @Prop({ default: [] }) personImages: {
    url: string
    EqualWH: boolean
    Typesetting: 'horizontal' | 'vertical'
    BigPicture: boolean
    name: string
  }[]
  /**
   * 标题
   * @values '通行图片'
   */
  @Prop({ default: '通行图片' }) title: string

  /**
   * 图片是否可被选择
   */
  @Prop({ default: false }) editable: boolean

  private defaultImg = require('@/assets/images/dashboard/default-person.png')
  private showImg = false
  private showDialog = false
  private bigPictureUrl = ''

  mounted() {
    this.showDialog = this.showFlag
  }
  change(picture: any) {
    if (this.editable && picture.url) {
      this.$emit('change', picture.url)
      this.showDialog = false
    }
  }
  deactivated() {
    this.showImg = false
    this.showDialog = false
  }
  showBigPicture(url: string) {
    this.showImg = true
    this.bigPictureUrl = url
  }
  computedClass(item: AnyObj) {
    if (!item.EqualWH) {
      return item.Typesetting
    }
  }
}
