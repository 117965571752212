

























import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  name: 'pageTitle'
})
export default class PageTitle extends Vue {
  @Prop({}) title: AnyObj[]
  @Prop({ default: false }) isContent: boolean
  @Prop({ default: true }) isTitle: boolean
  @Prop({ default: false }) isCustom: boolean

  get pageTitle() {
    if (this.title) {
      return this.title.filter((_, i) => i).map(i => i.name)
    } else return ''
  }
}
