





























































































































































































































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import RadioDialog from '@/components/common/RadioDialog/index.vue'
import pagination from '@/components/common/pagination/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import { exportStatistic } from '@/utils/export'
import pageTitle from '@/components/common/pageTitle.vue'
import AccessImage from '@/components/dashboard/AccessImage/index.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import { areaPath } from '@/utils/getAreaPath'
import SecurityText from '@/components/common/SecurityText/index.vue'
import _ from 'lodash'
import { tableScrollTop } from '@/utils/dom'

@Component({
  name: 'detailPage',
  components: {
    FeatureImg,
    pagination,
    CopyText,
    pageTitle,
    RadioDialog,
    InputSearch,
    AccessImage,
    SecurityText
  }
})
export default class DetialPage extends Vue {
  @Prop({ type: String, default: 'base' }) moduleName: 'base' | 'europa'

  @Ref('paginationNode') readonly paginationNode: pagination
  @Ref('exportDialog') readonly exportDialog: RadioDialog

  get apiModule() {
    return this.$api[this.moduleName]
  }

  get user() {
    return this.$store.state.user || {}
  }
  get currentAreaId() {
    return this.$store.state.areaId
  }

  get typeOptions() {
    return [
      { value: 0, label: '人脸识别' },
      { value: 1, label: '身份证' },
      { value: 2, label: '刷卡' },
      { value: 3, label: '二维码' },
      { value: 7, label: '其他' }
    ]
  }

  get reasonOptions() {
    return [
      { value: 1, label: '无效人脸' },
      { value: 2, label: '无效身份证' },
      { value: 3, label: '无效卡' },
      { value: 4, label: '无效二维码' },
      { value: 5, label: '温度异常' }
    ]
  }

  get placeholder() {
    const { searchType } = this.filterData

    return '请输入' + (this.selectList.find(item => item.value === searchType)?.label ?? '')
  }

  private query = {
    limit: 20,
    offset: 0
  }
  private showFlag = false
  private sels: any[] = []
  private passList: any = []
  private loading = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')],
    searchType: 0
  }
  private selectList = [
    { label: '姓名', value: 0 },
    { label: 'ID', value: 1 },
    { label: '身份证号', value: 2 }
  ]
  private searchPar: any = null
  private personImg: any[] = [
    {
      name: '注册图片',
      url: null,
      EqualWH: true,
      BigPicture: false
    },
    {
      name: '通行图片',
      url: null,
      EqualWH: false,
      Typesetting: 'vertical',
      BigPicture: true
    }
  ]
  private showExtendInfo = false
  private extendInfo: AnyObj = {}
  private reasonMap: AnyObj = {
    5: '温度异常',
    4: '无效二维码',
    3: '无效卡',
    2: '无效身份证',
    1: '无效人脸',
    20: '其他'
  }
  extendStatus = [2, 5]

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  init() {
    this.getList(this.query.limit, 0)
  }
  deactivated() {
    this.showFlag = false
    this.showExtendInfo = false
  }
  isExtendInfo(val: AnyObj) {
    const { result, comment } = val
    if ((result && this.extendStatus.includes(result)) || comment) return false
    return true
  }

  private showExportDialog() {
    this.exportDialog.show()
  }

  private async exportResult(exportAll = true) {
    let params = this.searchPar
    delete params.limit
    delete params.offset
    if (!exportAll) {
      if (this.sels.length === 0) return
      params = { ids: this.sels.map(i => i.id), detail: true }
    }

    if (this.searchPar !== null) {
      const res = await this.apiModule.getPassagesExport(params)
      exportStatistic(res.data, 'csv', 'passages.csv')
    }
  }
  private async pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getList(this.query.limit, this.query.offset)
  }
  async getList(pageSize = 20, offset = 0) {
    this.loading = true
    try {
      const resp = await this.getPassList(pageSize, offset)
      if (resp.data && resp.data.data) {
        const { data, count } = resp.data
        this.passList = data.map((item: AnyObj) => {
          Object.assign(item, areaPath(item.areas))
          return item
        })
        this.paginationNode.init({ total: count ? count : 0 })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }
  private async getPassList(pageSize = 20, offset = 0) {
    const val = this.filterData
    const [startTime, endTime] = val.dateRange

    const par: AnyObj = {
      startTime,
      endTime,
      limit: pageSize,
      offset: offset,
      areaIds: val.areaIds && val.areaIds.length > 0 ? val.areaIds : [this.root],
      passed: false,
      detail: true,
      identity: val.identity
    }
    if (val.passType || val.passType === 0) {
      Object.assign(par, { types: [val.passType] })
    }
    if (val.failedReason || val.failedReason === 0) {
      Object.assign(par, { reasons: [val.failedReason] })
    }
    if (val.searchText && !val.searchText.match(/^[ ]*$/)) {
      switch (val.searchType) {
        case 0:
          par.name = val.searchText
          break
        case 1:
          par.personIds = [val.searchText]
          break
        case 2:
          par.identityNumber = val.searchText
      }
    }
    this.searchPar = par

    return this.apiModule.getPassagesSearch(par)
  }

  private handleFilter() {
    this.paginationNode.initPage({ current: 1 })
    this.getList(this.query.limit, 0)
  }

  private async seePassImg(obj: AnyObj) {
    if (obj.targetImageUrl || obj.sceneImageUrl) {
      this.showFlag = true
      this.personImg[0].url = obj.targetImageUrl
      this.personImg[1].url = obj.sceneImageUrl
    }
  }

  seeExtendInfo(obj: AnyObj) {
    const { result, comment } = obj
    if ((result && this.extendStatus.includes(result)) || comment) {
      this.showExtendInfo = true
      this.extendInfo = _.cloneDeep(obj)
    }
  }

  private selsChange(val: any) {
    this.sels = val
  }
}
