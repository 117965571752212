export const telType = (tel: string) => (tel && tel.indexOf('-') > 0 ? 'landline' : 'phone')

export const secure: Record<string, (v: string) => string> = {
  mobilePhone: val => {
    const type = telType(val)
    if (type === 'landline') {
      return val.replace(/([0-9]{3,4})(-[0-9]{3,4})([0-9]{4})/, '$1****$3')
    } else {
      return val.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
    }
  },

  telNumber: val => val.replace(/^(.{3})(?:\d+)(.{4})$/, '$1****$2'),

  identityNumber: val => val.replace(/^(.{6})(?:\d+)(.{4})$/, '$1****$2'),

  bankCardNumber: val => val.replace(/^(.{3})(?:\d+)(.{3})$/, '$1****$2'),

  fullName: () => '***',

  mailbox: val => {
    const str = val.split('@')
    let _s = ''
    if (str[0].length >= 6) {
      return str[0].substr(0, 3) + '***' + '@' + str[1]
    } else {
      for (let i = 1; i < str[0].length; i++) {
        _s += '*'
      }
      return str[0].substr(0, 1) + _s + '@' + str[1]
    }
  },

  plateNumber: val => val.replace(/^(.{2})(?:\d+)(.{2})$/, '$1***$2')
}
