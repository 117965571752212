










import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator'
import { secure } from '@/utils/secureRules'

/**
 * 需要手动显示或隐藏敏感的文本信息时，可以使用 Security Text
 * @displayName Security Text
 */
@Component
export default class SecurityText extends Vue {
  @PropSync('visible', { default: false }) syncedVisible: boolean
  /**
   * 原始文本
   */
  @Prop({ default: '' }) text: string
  /**
   * 原始文本的类型
   */
  @Prop({ default: '' }) type: string

  /**
   * 将原始文本处理为安全性文本的方法
   *
   * @param {string} text 原始文本
   * @return {string} securityText 被处理的安全性文本
   */
  @Prop({ default: (text: string) => text }) secure: (text: string) => string

  private selfVisible = false

  @Watch('visible')
  watchVisible() {
    this.selfVisible = this.visible
  }

  @Watch('selfVisible')
  watchSelfVisible() {
    this.$emit('update:visible', this.selfVisible)
  }

  created() {
    this.selfVisible = this.syncedVisible
  }

  private get securityText() {
    if (!this.text) return '-'
    if (this.secure) return this.secure(this.text)
    if (secure[this.type]) return secure[this.type](this.text)

    return this.text.replace(/^(.{2})(?:.+)(.{2})$/, '$1****$2')
  }

  private toggle() {
    this.selfVisible = !this.selfVisible
  }
}
