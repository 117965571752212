import store from '@/store'
const areaId = store.state.config.root.id
const user = store.state.user || {}
const isRootAccount = areaId === user.scope

export function areaPath(val: { name: string }[]) {
  let res
  if (val && val.length > 0) {
    let index = val.findIndex((i: AnyObj) => i.id === areaId)
    if (!isRootAccount && index > 0) index = index - 1
    const arr = val.filter((i: AnyObj, m: number) => m > index).map((n: AnyObj) => n.name)
    if (arr.length > 0) {
      res = arr.join('/')
    } else {
      res = '-'
    }
  } else {
    res = '-'
  }
  return { areaPath: res, disabledTooltip: res === '-' }
}
