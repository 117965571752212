export async function exportStatistic(data: any, exportType: string, name: string) {
  let url
  if ('msSaveOrOpenBlob' in navigator) {
    let blob: Blob
    if (exportType === 'csv') {
      blob = new Blob(['\ufeff' + data], { type: 'text/csv,charset=UTF-8' })
    } else {
      blob = new Blob([data], { type: exportType })
    }
    window.navigator.msSaveOrOpenBlob(blob, name)
  } else {
    if (exportType === 'csv') {
      const csvContent = new Blob(['\ufeff' + data], { type: 'text/csv,charset=UTF-8' })
      url = URL.createObjectURL(csvContent)
    } else {
      url = URL.createObjectURL(new Blob([data], { type: exportType }))
    }
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href) // 释放URL 对象
    document.body.removeChild(link)
  }
}
